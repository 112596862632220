import * as React from "react"
import { Router } from "@reach/router"

import Layout from "../components/Layout"
import defaults from "../components/dafaults"

const SomeSubPage = props => {

  const [ data, setData ] = React.useState(null)
  const [ currentPage, setCurrentPage ] = React.useState(null)

  React.useLayoutEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${defaults.api.rest}posts?slug=${props.id}&_embed`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(props.id !== currentPage) {
              setCurrentPage(props.id)
              setData(result[0])
            }
        })
        .catch(error => console.log('error', error));
  })

  console.log("🚀 ~ file: produtos.js ~ line 10 ~ SomeSubPage ~ data", data)

  if(data == null) return null

  return (
    <div className="content somesubpage" id={props.id}>
      <div className="flex">
            <h1 className="title" dangerouslySetInnerHTML={{__html: data.title.rendered }}></h1>
            <a href="/dica" className="btn-default">Ver todas as Dicas</a>
      </div>
      <div className="insideContent">
        <img src={data._embedded["wp:featuredmedia"][0].source_url} alt={data.title.rendered} />
        <div dangerouslySetInnerHTML={{__html: data.content.rendered }} />
      </div>
    </div>
  )
}

const Dicas = ({ serverData }) => (
  <Layout>
    <Router>
      <SomeSubPage path="/dicas/:id" />
    </Router>
  </Layout>
)

export default Dicas